<template>
  <div
    class="flex-grow-1"
    :class="{'d-flex': isDashboardV6, 'flex-column': isDashboardV6, 'display-contents': !isDashboardV6}"
  >
    <component
      v-bind:is="currentComponent"
      :show-all.sync="showAll"
      class="overflow-y-hidden"
    />
  </div>
</template>

<script>

import auditController from '@/auditController'
// import tabController from '@/tabController'
import { MainPresetPart } from '@/wasm/pkg'

export default {
  components: {
    'dashboard-agent': () => import('./agent/dashboard-agent'),
    'dashboard-admin': () => import('./dashboard-admin'),
    'dashboard-agent-supervisor': () => import('./dashboard-agent-supervisor'),
    'dashboard-division-manager': () => import('./dashboard-division-manager')
  },
  activated () {
    this.mainPresetPart.active = true
    this.auditController.stopAudit()
  },
  destroyed () {
    this.mainPresetPart.free()
  },
  computed: {
    currentComponent () {
      let component = 'dashboard-admin'
      switch (this.$store.getters.currentUser.groupCode) {
        // case 'agent':
        //   component = 'dashboard-agent'
        //   break
        // case 'agentSupervisor':
        //   component = 'dashboard-agent-supervisor'
        //   break
        case 'business_division_supervisor':
          component = 'dashboard-division-manager'
          break
        default:
          component = 'dashboard-agent'
      }

      return component
    },
    isDashboardV6 () {
      return this.currentComponent !== 'dashboard-division-manager'
    },
    showAll () {
      return typeof this.mainPresetObs?.doc?.myArea === 'undefined' ? undefined : !(this.mainPresetObs?.doc?.myArea)
    }
  },
  data () {
    return {
      auditController: auditController,
      mainPresetObs: null,
      mainPresetPart: new MainPresetPart()
    }
  },
  async mounted () {
    if (this.isDashboardV6) {
      this.mainPresetPart.setKey('dashboard')
      this.mainPresetPart.active = true
      this.mainPresetObs = this.mainPresetPart.obs
    }
  },
  deactivated () {
    this.mainPresetPart.active = false
    this.$removeFromKeepAliveCache()
    this.$destroy()
  }
}
</script>

<style lang="stylus" scoped>
.display-contents
  display contents
</style>
